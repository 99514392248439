@import "../../global-styles/terra.scss";

.ter-feat-eight {
  padding: spacing("md");
  margin: auto;
  position: relative;

  @media (min-width: $breakpoint-xs) {
    padding: spacing("xxl") spacing("md");
  }

  &__header {
    margin: auto;
    font-size: font-size("lg");
    text-align: center;
  }

  &__sub-header {
    padding: 16px 16px 0;
    margin: auto;
    text-align: center;
  }

  .ter-button {
    @media (min-width: $breakpoint-xs) {
      width: auto;
      display: inline-block;
      padding: 0 spacing("xxl");
    }
  }

  &__button-link-wrapper {
    @media (min-width: $breakpoint-xs) {
      text-align: center;
    }
  }

  &__card-container {
    // @include grid-container();
    // padding: spacing("lg") 0 0;
    padding-top: spacing("lg");
    @include vendor-prefix-display-flex;
    flex-wrap: wrap;

    @media (min-width: $breakpoint-xs) {
      justify-content: center;
    }
  }

  .ter-card {
    // @include grid-column(12);
    margin: spacing("lg") auto 0;

    @media (min-width: $breakpoint-xs) {
      max-width: 300px;
      margin: 0 spacing("sm") spacing("lg");

      // @include grid-column(6);
      // align-self: center;
    }
  }

  &__bottom-link {
    &-wrapper {
      width: min-content;
      margin: 96px auto 0 !important;
    }

    display: block !important;
    // width: fit-content;
  }

  &--brand-regular {
    @include light-brand-bg;
    h2 {
      @include brand-theme-text;
    }
  }
  &--brand-dark {
    @include dark-brand-bg;
    h2 {
      @include brand-theme-text;
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-bg;
    h2 {
      @include brand-theme-text;
    }
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
  }

  &--theme-2 {
    @include theme-2;
  }

  &--theme-3 {
    @include theme-3;
  }

  &--theme-4 {
    @include theme-4;

    h2 {
      @include brand-theme-text;
    }

    .ter-card__body p {
      color: color("gray-7");
    }
  }

  &--theme-5 {
    @include theme-5;

    h2 {
      @include brand-theme-text;
    }

    .ter-card__body p {
      color: color("gray-7");
    }
  }

  &__background-image {
    position: absolute;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}

.luna-feat-8-cta-container {
  margin: 16px auto 0;
  @include vendor-prefix-display-flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 600px) {
    display: block;
    margin: 32px auto 0;
  }
  .ter-button {
    margin: 16px 24px 0;
    display: block;
  }
}
