@import "../../global-styles/terra.scss";

.ter-hero-one {
  height: auto;
  margin-bottom: -7px;
  // This negative margin is bullshit, but I can't figure out what's causing this to get an extra 7px at the bottom.

  &__max-width-wrapper {
    max-width: 1200px;
    margin: auto;
    position: relative;
  }
  &__image {
    width: 100%;
    background-size: cover;

    &--with-motion {
      animation: fade-in 0.5s;
    }

    &--mobile {
      max-height: 75vh;
      width: 100vw;
      height: 800px;
    }

    &--tablet {
      max-height: 75vh;
      width: 100vw;
      height: 800px;
    }

    &--desktop {
      width: 100vw;
      height: 700px;
      max-height: 90vh;
    }
  }

  &__content-container {
    background: color("white");
    padding: spacing("md") spacing("sm");
    max-width: 85%;
    margin: auto;
    transform: translateY(-100px);
    box-shadow: $card-box-shadow;
    position: relative;

    &--with-motion {
      animation: fade-up 1s;
    }

    @media (min-width: $breakpoint-xs) {
      padding: spacing("md") spacing("md");
    }

    @media (min-width: $breakpoint-sm) {
      max-width: 700px;
    }

    @media (min-width: $breakpoint-md) {
      margin: 0;
    }
  }

  &__header {
    font-size: font-size("lg");
    margin: 0;

    @media (min-width: $breakpoint-sm) {
      font-size: font-size("xl");
    }
  }

  &__text {
    font-size: font-size("base");
    @include opensans-light();
    margin: 0;
    padding-top: 18px;

    @media (min-width: $breakpoint-sm) {
      font-size: font-size("md");
    }
  }

  &__text-holder {
    padding-top: 18px;

    p {
      margin: 0;
    }
  }

  .ter-button {
    display: block;

    &--secondary--1 {
      margin-top: spacing("xs") !important;
    }

    @media (min-width: $breakpoint-md) {
      // margin-top: spacing("md");
    }
  }

  .ter-cta-section {
    margin-top: 32px;
  }

  .ter-cta-section__link {
    display: block;
    text-align: center;
  }

  &--brand-regular {
    @include light-brand-bg;

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }
  &--brand-dark {
    @include dark-brand-bg;

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-bg;

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }
  &--light {
    @include light-theme;

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }
  &--dark {
    @include dark-theme;

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }

  &--theme-1 {
    @include theme-1;

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }

  &--theme-2 {
    @include theme-2;

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }

  &--theme-3 {
    @include theme-3;

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }

  &--theme-4 {
    background: color("brand-2");

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }

  &--theme-5 {
    background: color("brand-1");

    @media (min-width: $breakpoint-md) {
      background-color: transparent;
    }
  }

  &__wrapper {
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    top: 100px;
  }
  30% {
    opacity: 0;
    top: 100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
