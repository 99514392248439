@import "../global-styles/terra.scss";

.ter-cta-section {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  @include for-size(tablet-portrait-up) {
    width: auto;
    min-width: 150px;
  }
  > a {
    text-align: center;
    display: block;
    width: 100%;
  }
  .ter-button {
    &:nth-child(2) {
      margin-top: spacing("sm");
    }
  }
  .ter-sub-cta {
    margin-top: spacing("xxs");
    font-size: font-size("sm");
  }
}