@import "../global-styles/terra.scss";

.ter-filter {
  width: 250px;
  position: relative;
  background: gray("gray-1");

  &__title {
    font-size: font-size("sm");
    border: solid 1px #000;
    position: relative;
    padding: spacing("xxs") spacing("xs");
    margin-bottom: 0;
    height: spacing("lg");

    &:hover {
      cursor: pointer;
    }
  }

  .ter-icon {
    position: absolute;
    right: spacing("xs");
    top: 16px;
  }
  .ter-checkbox {
    padding: 0;
  }

  &__option-container {
    background: gray("gray-1");

    width: 250px;
    border: solid 1px #000;
    margin-top: 0;
    padding: spacing("xs");
    position: absolute;
    z-index: 1000;
    top: 49px;

    &.false {
      visibility: hidden;
    }
  }
}
