@import "../../global-styles/terra.scss";

.ter-feat-seven {
  max-width: 1400px;
  padding: spacing("md");
  margin: spacing("auto");
  position: relative;

  &__header {
    text-align: center;
    font-size: font-size(lg);
    @include opensans-very-bold();
  }

  &__card-container {
    @media (min-width: $breakpoint-sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: spacing("lg");
    }
    margin: spacing("lg") spacing("auto") 0;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__card {
    text-decoration: none;
    margin: 0 spacing("auto");
    max-width: 600px;
    @include vendor-prefix-display-flex;
    border-top: solid 1px color("gray-3");
    border-bottom: solid 1px color("gray-3");
    padding: spacing("md");
    color: #333;
    transition: 0.2s;
    height: 100%;

    &:hover {
      background: color("white");
      box-shadow: box-shadow(4);
      text-decoration: none;
    }
  }

  &__card-text {
    color: #333;
    margin-bottom: 0;
  }

  &__card-content-wrapper {
    padding-right: spacing("md");
  }

  &__icon-wrapper {
    width: 50px;
    align-self: center;
  }

  &--brand-regular {
    @include light-brand-theme;
    p,
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon {
      @include brand-theme-fill;
    }
    .ter-feat-seven {
      &__card {
        &:hover {
          @include light-brand-theme;
        }
      }
    }
  }
  &--brand-dark {
    @include dark-brand-theme;
    p,
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon {
      @include brand-theme-fill;
    }
    .ter-feat-seven {
      &__card {
        &:hover {
          @include dark-brand-theme;
        }
      }
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-theme;
    p,
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon {
      @include brand-theme-fill;
    }
    .ter-feat-seven {
      &__card {
        &:hover {
          @include very-dark-brand-theme;
        }
      }
    }
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
  }

  &--theme-2 {
    @include theme-2;
  }

  &--theme-3 {
    @include theme-3;
  }

  &--theme-4 {
    @include theme-4;

    p,
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon {
      @include brand-theme-fill;
    }
    .ter-feat-seven {
      &__card {
        &:hover {
          @include light-brand-theme;
        }
      }
    }
  }

  &--theme-5 {
    @include theme-5;
    p,
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon {
      @include brand-theme-fill;
    }
    .ter-feat-seven {
      &__card {
        &:hover {
          @include very-dark-brand-theme;
        }
      }
    }
  }

  &__background-image {
    position: absolute;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}

@media (max-width: 600px) {
  .ter-feat-seven {
    &__card {
      display: block;
    }
    &__icon-wrapper {
      margin-top: 16px;
    }
  }
}
