@import "../../global-styles/terra.scss";

.ter-feat-four-flex {
  padding-top: 96px;
  padding-bottom: 96px;
  margin: auto;
  position: relative;

  &__header {
    margin: auto;
    font-size: font-size("lg");
    text-align: center;
    padding-bottom: 32px;
    @include opensans-very-bold();
  }

  &__sub-header {
    padding: 0 16px 48px 0;
    margin: auto;
    text-align: center;
  }

  &__card-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
  }

  .ter-card {
    width: 288px;
    margin: 0 16px 48px;
    background-color: color("white");
  }

  .ter-logo-dumpling {
    p {
      font-size: 20px;
      line-height: 1.6;
      margin: 0 auto;
      padding-top: 8px;
      text-align: center;
    }
  }

  .ter-button {
    height: 32px;
    line-height: 31px;
  }

  &--brand-regular {
    @include light-brand-bg;
  }
  &--brand-dark {
    @include dark-brand-bg;
  }
  &--brand-very-dark {
    @include very-dark-brand-bg;
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }
  &--with-dumplings {
    @include brand-theme-text;
  }

  &--theme-1 {
    @include theme-1;
  }

  &--theme-2 {
    @include theme-2;
  }

  &--theme-3 {
    @include theme-3;
  }

  &--theme-4 {
  }

  &--theme-5 {
    background: color("brand-1");
  }

  &__background-image {
    position: absolute;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
      .ter-feat-four-flex__header,
      .ter-feat-four-flex__sub-header {
        @include brand-theme-text;
      }
    }
    &--theme-5 {
      background: color("brand-1");
      .ter-feat-four-flex__header,
      .ter-feat-four-flex__sub-header {
        @include brand-theme-text;
      }
    }
  }
}
