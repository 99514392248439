.luna-vidyard-modal {
  display: flex;
  align-items: center;

  .vidyard-player-embed {
    visibility: hidden;
  }

  .vidyard-player-container {
    animation: fadein 2000 linear;
    animation-delay: 1000;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
