@import "../global-styles/terra.scss";

.ter-modal {
  @include depth(2);
  width: 100%;
  margin: spacing("xs");
  position: relative;
  -webkit-transition: -webkit-transform 225ms ease-in-out;
  transition: -webkit-transform 225ms ease-in-out;
  -o-transition: transform 225ms ease-in-out;
  transition: transform 225ms ease-in-out;
  transition: transform 225ms ease-in-out, -webkit-transform 225ms ease-in-out;
  width: auto;
  &__header {
    @include header-type-setting();
    font-size: font-size("base");
  }
  &__body {
    padding: spacing("md") spacing("sm");
    font-size: $modal-font-size;
    p {
      font-size: $modal-font-size;
    }
  }
  &__wrap {
    background: $modal-bg;
    margin: 0 auto;
    position: relative;
  }
  &__close {
    display: block;
    position: absolute;
    top: spacing("xs");
    right: spacing("xs");
    opacity: 0.4;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    width: 16px;
    height: 16px;
    &:after {
      @include icon-base();
      content: "";
      position: absolute;
      top: 50%;
      right: 0px;
      -webkit-transform: rotate(0deg) translateY(-50%);
          -ms-transform: rotate(0deg) translateY(-50%);
              transform: rotate(0deg) translateY(-50%);
      -webkit-transition: -webkit-transform 200ms ease-in-out;
      transition: -webkit-transform 200ms ease-in-out;
      -o-transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
      background-position: -316px -316px;
      height: 20px;
      width: 20px;
    }
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .ter-cta-section {
    .ter-button {
      margin: spacing("xs") auto 0;
      text-align: center;
      display: block;

      @media (min-width: $breakpoint-xs) {
        margin: spacing("xs") 0 0;
      }
    }
  }

  .ter-cta-section__link {
    margin-top: spacing("xs");
    display: block;

    @media (min-width: $breakpoint-xs) {
      text-align: center;
    }
  }
}

body.has-modal-open {
  overflow: hidden;
}

.ter-modal--full-page {
  @include z-index(10);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: transparentize($modal-overlay-bg, 0.25);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  &.is-open {
    opacity: 1;
    visibility: visible;
    overflow: scroll;
    .ter-modal {
      -webkit-transform: translateY(0px);
          -ms-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  .ter-modal {
    position: relative;
    top: 0;
    padding: 0;
    -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
            transform: translateY(20px);
    width: 600px;
    margin: spacing("xxl") auto;
    &__wrap {
      @include for-size(desktop-up) {
        width: 100%;
      }
    }
  }

  .ter-cta-section {
    .ter-button {
      margin: spacing("xs") auto 0;
      text-align: center;
      display: block;

      @media (min-width: $breakpoint-xs) {
        margin: spacing("xs") 0 0;
      }
    }
  }
}