@import "../global-styles/terra.scss";

.ter-headshot-dumpling-container {
  @include vendor-prefix-display-flex;
  padding: 0 16px;
  -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1200px;
  margin: auto;
}
