@import '../global-styles/terra.scss';

@mixin alert-base() {
    padding: $alert-padding;
    display: block;
    margin-bottom: spacing('sm');
    position: relative;
    border-radius: $alert-border-radius;
    &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        opacity: 0.4;
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0;
        width: 20px;
        height: 20px;
        &:after {
            @include icon-base();
			content: '';
			position: absolute;
			top: 50%;
			right: 0px;
			-webkit-transform: rotate(0deg) translateY(-50%);
                -ms-transform: rotate(0deg) translateY(-50%);
                    transform: rotate(0deg) translateY(-50%);
                        -webkit-transition: -webkit-transform 200ms ease-in-out;
                        transition: -webkit-transform 200ms ease-in-out;
                        -o-transition: transform 200ms ease-in-out;
                        transition: transform 200ms ease-in-out;
                        transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
			background-position: -316px -316px;
            height: 20px;
            width: 20px;
		}
        &:hover {
            opacity: 0.6;
            cursor: pointer;
        }
    }
}

@mixin alert-variant($color, $bg: transparent) {
    background-color: transparentize($color, .9);
    border-top: 1px solid darken($color, 10%);
    border-right: 1px solid darken($color, 10%);
    border-bottom: 3px solid darken($color, 10%);
    border-left: 1px solid darken($color, 10%);
    .ter-alert__icon {
        > i {
            &:before {
                color: darken($color, 20%);
            }
        }
    }
    .ter-alert__close {
        i {
            color: darken($color, 15%);
        }
    }
    hr {
        opacity: 0.4;
        background-color: darken($color, 10%);
    }
    a {
        color: darken($color, 20%);
        text-decoration: underline;
        &:hover {
            color: darken($color, 10%);
            text-decoration: underline;
        }
    }
}

.ter-alert {
    @include alert-base();
}

@each $color, $value in $emotive-colors {
  .ter-alert--#{$color} {
    @include alert-variant($value);
  }
}
