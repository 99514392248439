@mixin vendor-prefix-display-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

@mixin vendor-prefix-flex-1 {
-webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1
}

@mixin vendor-prefix-flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@mixin vendor-prefix-flex-direction-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@mixin vendor-prefix-justify-content-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

@mixin vendor-prefix-justify-content-space-between {
    -webkit-box-pack: justify;
      -ms-flex-pack: justify;
        justify-content: space-between;
}

@mixin vendor-prefix-flex-wrap-row {
  -ms-flex-wrap: row;
      flex-wrap: row
}

@mixin vendor-prefix-flex-grow-1 {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1
}