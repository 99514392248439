@import "../../global-styles/terra.scss";

.luna-region-card {
  width: 230px;
  font-size: font-size("nano");
  margin: 0 auto 20px;

  &__region {
    font-size: font-size("nano");
    @include opensans-medium();
    margin: 0;
    height: 30px;
    line-height: 30px;
    padding-left: 8px;
    background: color("brand-2");
    color: gray("gray-2");
    border-top: solid color("brand-2") 2px;
    border-left: solid color("brand-2") 2px;
    border-right: solid color("brand-2") 2px;
  }

  &__language {
    list-style: none;
    cursor: pointer;

    &:hover {
      color: $mep-blue-2;
    }
  }
  ul {
    margin: 0;
    padding-top: 8px;
    padding-bottom: 2px;
    border-bottom: solid color("gray-3") 2px;
    border-left: solid color("gray-3") 2px;
    border-right: solid color("gray-3") 2px;
    background: color("gray-3");
  }

  &:hover {
    ul {
      border-bottom: solid color("brand-2") 2px;
      border-left: solid color("brand-2") 2px;
      border-right: solid color("brand-2") 2px;
    }

    .luna-region-card__region {
      // background: $mep-blue-2;
      border-top: solid color("brand-2") 2px;
      border-left: solid color("brand-2") 2px;
      border-right: solid color("brand-2") 2px;
    }
  }

  &--inactive {
    // border: solid 2px rgba(0, 0, 0, 0.2);

    .luna-region-card__region {
      background: $mep-gray-2;
      border-top: solid $mep-gray-2 2px;
      border-left: solid $mep-gray-2 2px;
      border-right: solid $mep-gray-2 2px;
      color: color("gray-7");
    }

    .luna-region-card__language {
      // color: rgba(0, 0, 0, 0.2);
    }
  }

  &__language--is-active {
    &:before {
      content: "•";
      font-size: 300%; /* or whatever */
      line-height: 0px;
      position: absolute;
      top: 12px;
      left: -20px;
    }
    color: color("brand-2");
    position: relative;
  }
}
