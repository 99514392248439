@import "../../global-styles/terra.scss";

.ter-feat-four {
  margin: spacing("auto");
  position: relative;
  padding-top: 60px;
  padding-bottom: 72px;

  &__header {
    color: $body-font-color;
    text-align: center;
    font-size: font-size(lg);
    margin-bottom: spacing("sm");
    padding: 0 32px;

    &--no-image {
      padding-top: spacing("md");
    }
  }

  &__text,
  &__text-holder p {
    color: $body-font-color;
    text-align: center;
    padding: 0 32px;
  }

  &__card-container {
    display: grid;
    padding: spacing("none") spacing("xs");

    &.three-columns {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.two-columns {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__image {
    margin: auto;
    display: block;
    width: 100%;
    height: auto;
    padding: spacing("md");
  }

  // THEME COLORS

  &--light {
    @include light-theme;
  }

  &--dark {
    @include dark-theme;
  }

  &--brand-regular {
    @include light-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &--brand-dark {
    @include dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &--brand-very-dark {
    @include very-dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &--default {
    .ter-icon-list-item {
      svg {
        fill: color("gray-7");
      }
    }
  }

  &--theme-1 {
    @include theme-1;
    h2,
    h3,
    h4,
    button {
      color: color("gray-7");
    }

    .ter-icon-list-item {
      svg {
        fill: color("gray-7");
      }
    }
  }

  &--theme-2 {
    @include theme-2;

    h2,
    h3,
    h4,
    button {
      color: color("gray-7");
    }

    .ter-icon-list-item {
      svg {
        fill: color("gray-7");
      }
    }
  }

  &--theme-3 {
    @include theme-3;

    h2,
    h3,
    h4,
    button {
      color: color("gray-7");
    }

    .ter-icon-list-item {
      svg {
        fill: color("gray-7");
      }
    }
  }

  &--theme-4 {
    @include theme-4;

    h2,
    h3,
    h4,
    .ter-feat-four__text,
    .ter-feat-four__text-holder p,
    button {
      color: color("gray-1");
    }
    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &--theme-5 {
    @include theme-5;
    h2,
    h3,
    h4,
    .ter-feat-four__text,
    .ter-feat-four__text-holder p,
    button {
      color: color("gray-1");
    }

    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &__background-image {
    position: absolute;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}

@media (max-width: 1200px) {
  .ter-feat-four {
    &__card-container {
      &.three-columns {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media (max-width: 900px) {
  .ter-feat-four {
    &__card-container {
      &.three-columns {
        grid-template-columns: 1fr 1fr;
      }
    }

    .ter-icon-list-item {
      display: block;
    }
  }
}

@media (max-width: 600px) {
  .ter-feat-four {
    &__card-container {
      &.three-columns,
      &.two-columns {
        grid-template-columns: 1fr;
      }
    }
  }
}
