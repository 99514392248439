@import "../../global-styles/terra.scss";

.luna-nav-search {
  display: flex;
  margin-right: 0px;
  width: 0;
  overflow: hidden;
  transition: 0.3s;

  &--deployed {
    width: 200px;
  }

  &__input {
    border: solid 1px $mep-blue-1;
    margin: 0;
    height: 28px;
    font-size: font-size("micro");
    width: 200px;
    padding-left: 8px;
  }
}
