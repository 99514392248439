@import "../../global-styles/terra.scss";

.ter-hero-3 {
  &__hero-image {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    img {
      width: 100%;
      height: auto;
      display: block;
    }

    &--with-motion {
      animation: fade-in 0.5s;
    }

    &--4x1 {
      display: none;
      @media screen and (min-width: $breakpoint-sm) {
        display: block;
      }
    }
    &--2x1 {
      display: block;
      @media screen and (min-width: $breakpoint-sm) {
        display: none;
      }
    }
  }
  &__container {
    @include container-wrap;
    @include vendor-prefix-display-flex;
    width: 100%;
    height: auto;
    transform: translate(0, calc(#{spacing("xxl")} * -1));
    justify-content: center;
    position: relative;
    @media screen and (min-width: $breakpoint-xs) {
      transform: translate(0, calc(#{spacing("super")} * -1));
    }

    &--with-motion {
      animation: fade-up 1s;
    }
  }
  &__card {
    background-color: rgba(255, 255, 255, 0.95);
    display: block;
    flex-basis: 550px;
    margin-top: auto;
    margin-bottom: calc(#{spacing("md")} * -1);
    align-self: center;
    @media screen and (min-width: $breakpoint-xs) {
      margin-bottom: calc(#{spacing("sm")} * -1);
    }
  }
  &__header {
    font-size: font-size("xl");
    text-align: center;
  }
  &__sub-header {
    font-size: font-size("md");
    @include opensans-light();
    margin-bottom: spacing("md");
    text-align: center;
  }

  .ter-button {
    text-align: center;
    display: block;
  }

  .ter-cta-section {
    margin: auto;
  }

  &__cta-section-wrapper {
    @include vendor-prefix-display-flex;
    justify-content: space-between;
  }

  &--brand-regular {
    @include light-brand-bg;
  }
  &--brand-dark {
    @include dark-brand-bg;
  }
  &--brand-very-dark {
    @include very-dark-brand-bg;
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    @include theme-2;
  }
  &--theme-3 {
    @include theme-3;
  }
  &--theme-4 {
    background: color("brand-2");
  }
  &--theme-5 {
    background: color("brand-1");
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    top: 100px;
  }
  30% {
    opacity: 0;
    top: 100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
