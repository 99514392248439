@import "../../global-styles/terra.scss";

.ter-hero-6 {
  background-color: color("white");
  position: relative;
  &--right {
    .ter-hero-6__hero-image {
      right: 0;
      left: unset;
    }
    .ter-hero-6__content {
      grid-column-end: auto;
    }
  }
  &__hero-image {
    display: block;
    @media screen and (min-width: $breakpoint-sm) {
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 54%;
    }
    img {
      width: 100%;
      height: auto;
      display: block;
    }
    &--3x2 {
      display: none;
      @media screen and (min-width: $breakpoint-md) {
        display: block;
      }
    }
    &--4x3 {
      display: block;
      @media screen and (min-width: $breakpoint-sm) {
        display: none;
      }
    }
    &--1x1 {
      display: none;
      @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        display: block;
      }
    }
  }
  &__container {
    @include grid-container();
  }
  &__content {
    padding: spacing("md") 0;
    @include grid-column(12);
    @media screen and (min-width: $breakpoint-sm) {
      @include grid-column(5);
      grid-column-end: -1;
      padding: spacing("mega") 0;
    }
  }

  &__header {
    font-size: font-size("xl");
  }
  &__sub-header {
    font-size: font-size("md");
    @include opensans-light();
    margin-bottom: spacing("md");
  }

  .ter-cta-section {
    .ter-button {
      margin: spacing("xs") auto 0;
      text-align: center;
      display: block;

      @media (min-width: $breakpoint-xs) {
        margin: spacing("xs") 0 0;
      }
    }
  }

  .ter-cta-section__link {
    display: block;

    @media (min-width: $breakpoint-xs) {
      text-align: center;
    }
  }

  &--brand-regular {
    @include light-brand-theme;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--brand-dark {
    @include dark-brand-theme;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-theme;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    @include theme-2;
  }
  &--theme-3 {
    @include theme-3;
  }
  &--theme-4 {
    @include theme-4;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--theme-5 {
    @include theme-5;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
}
