@import "../../global-styles/terra.scss";

.ter-feat-two {
  padding: spacing("lg");
  margin: auto;
  position: relative;
  color: $body-font-color;

  &__background-image {
    position: absolute;
  }

  &__image--mobile {
    margin: auto;
    display: block;
    width: 100%;
    height: auto;

    @media (min-width: $breakpoint-sm) {
      display: none;
    }
  }

  &__header {
    text-align: center;
    font-size: font-size("lg");
    max-width: 800px;
    margin: spacing("md") auto;
    color: $body-font-color;
    @include opensans-very-bold();
  }

  &__text,
  &__text-holder p {
    max-width: 800px;
    margin: spacing("md") auto;
    text-align: center;
    color: $body-font-color;
  }

  &__accordion-image-container {
    @media (min-width: $breakpoint-sm) {
      @include vendor-prefix-display-flex;
      justify-content: space-around;
      padding: spacing("md");
    }
  }

  &__accordion-wrapper {
    @media (min-width: $breakpoint-sm) {
      padding: 0 spacing("md");
      width: 100%;
    }

    .ter-accordion {
      align-self: start;
    }
  }

  &__image {
    margin: auto;
    display: block;
    width: 100%;
  }

  &__image-wrapper {
    display: none;

    width: 100%;
    height: auto;
    margin: auto;
    padding-bottom: spacing("lg");

    @media (min-width: $breakpoint-sm) {
      padding: 0 spacing("md");
      display: block;
    }

    @media (min-width: $breakpoint-sm) {
      padding-bottom: 0;
    }
  }

  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }
  &--brand-regular {
    @include light-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-accordion {
      @include brand-theme-border;
      &__trigger-caret {
        @include brand-theme-fill;
      }
      &__fold {
        @include brand-theme-border;
      }
    }
  }
  &--brand-dark {
    @include dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-accordion {
      @include brand-theme-border;
      &__trigger-caret {
        @include brand-theme-fill;
      }
      &__fold {
        @include brand-theme-border;
      }
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-accordion {
      @include brand-theme-border;
      &__trigger-caret {
        @include brand-theme-fill;
      }
      &__fold {
        @include brand-theme-border;
      }
    }
  }

  &--theme-1 {
    @include theme-1;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-2 {
    @include theme-2;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-3 {
    @include theme-3;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-4 {
    @include theme-4;

    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-accordion {
      @include brand-theme-border;
      &__trigger-caret {
        @include brand-theme-fill;
      }
      &__fold {
        @include brand-theme-border;
      }
    }
  }

  &--theme-5 {
    @include theme-5;

    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-accordion {
      @include brand-theme-border;
      &__trigger-caret {
        @include brand-theme-fill;
      }
      &__fold {
        @include brand-theme-border;
      }
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;

    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}
