@import "../../global-styles/terra.scss";

.ter-hero-four {
  padding: 32px;
  @include vendor-prefix-display-flex;
  align-items: center;
  margin: auto;
  position: relative;
  opacity: 1;

  &--deployed {
    .ter-hero-four__play-btn {
      opacity: 0;
    }
  }

  @media screen and (max-width: 900px) {
    display: block;
    padding: 32px;
  }

  &--right {
    flex-direction: row-reverse;
  }

  &__thumbnail {
    box-sizing: border-box;
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
    // padding: 36px 36px
    transition: 0.2s;
    padding-right: 16px;

    &--is-hovering {
      transform: scale(1.03);
      cursor: pointer;
    }

    @media screen and (max-width: 900px) {
      padding: 0;
    }

    &-container {
      flex: 7;
      position: relative;

      @media screen and (max-width: 900px) {
        max-width: 100vw;
      }
    }
  }

  &__play-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: 0.2s;

    &--is-hovering {
      transform: scale(1.03);
      cursor: pointer;
    }
  }

  &__content-container {
    -webkit-box-flex: 5;
    -ms-flex: 5;
    flex: 5;

    padding: 16px;

    -ms-grid-column-align: center;
    justify-self: center;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      @include vendor-prefix-display-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    @media (max-width: 900px) {
      padding: 32px 0 0;
    }
  }

  &__header {
    font-size: font-size(lg);
  }

  &__text,
  &__text-holder p {
    margin-top: spacing("xs");
  }

  .ter-cta-section {
    min-width: 150px;
    width: min-content;

    .ter-button {
      margin: spacing("xs") auto 0;
      text-align: center;
      display: block;
    }
  }

  .ter-cta-section__link {
    text-align: center;
    display: block;
    clear: both;
  }

  // VIDEO MODAL STYLING //

  &__video-wrapper {
    position: relative;

    transition: 0.3s;
    transform: translateY(50px);
    opacity: 0;

    &--default {
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
    }

    &--is-deployed {
      transform: translateY(0);
      opacity: 1;
    }

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 90%;
      max-height: 90%;
      margin: auto;
    }
  }

  &__modal {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: 0.3s;
    animation: fade-in 0.5s linear;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    visibility: hidden;
    display: grid;
    align-items: center;

    &--is-deployed {
      opacity: 1;
      visibility: visible;
    }
  }

  &--brand-regular {
    @include light-brand-bg;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--brand-dark {
    @include dark-brand-bg;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }

    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-bg;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    @include theme-2;
  }
  &--theme-3 {
    @include theme-3;
  }
  &--theme-4 {
    background: color("brand-2");
    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--theme-5 {
    background: color("brand-1");
    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }

  &__wrapper {
    position: relative;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }

  .ter-hero-four__thumbnail,
  .ter-hero-four__thumbnail-container,
  .ter-hero-four__header,
  .ter-hero-four__text,
  .ter-hero-four__text-holder,
  .ter-cta-section {
    transition: opacity 0.2s;
  }

  &--deployed {
    .ter-hero-four__thumbnail,
    .ter-hero-four__thumbnail-container,
    .ter-hero-four__header,
    .ter-hero-four__text,
    .ter-hero-four__text-holder,
    .ter-cta-section {
      opacity: 0;
    }
  }
}
