@import "../global-styles/terra.scss";

.ter-button-link-card {
  padding: spacing("sm");

  &__header {
    font-size: font-size("md");
    @include opensans-very-bold();
  }
}
