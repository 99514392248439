@import "../global-styles/terra.scss";

.ter-dumpling {
  margin: spacing("auto");
  padding: spacing("sm") spacing("none");

  &__icon-wrapper {
    padding-bottom: spacing("sm");

    .ter-icon {
      margin: spacing("auto");
      display: block;
      fill: color("ui-1");
    }
  }

  &__small-dash {
    width: 25px;
    margin: 0 auto spacing("xs");
    border-top: solid 1px color("ui-1");
  }

  &__link {
    text-align: center;
    display: block;
    @include opensans-medium;
  }

  &__image {
    max-width: 150px;
    max-height: 150px;
    display: block;
    margin: spacing("none") spacing("auto") spacing("md");
    &--rounded {
      border-radius: 50%;
    }
  }
}
