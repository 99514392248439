@import "../global-styles/terra.scss";

@mixin notification-base() {
    font-size: font-size('sm');
    background-color: $noficiation-bg-color;
    padding: spacing('xs') spacing('sm');
    display: block;
    position: relative;
    border-radius: $notification-border-radius;
    margin-bottom: spacing('sm');
    &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        opacity: 0.4;
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0;
        &:after {
            @include icon-base();
			content: '';
			position: absolute;
			top: 50%;
			right: 0px;
			-webkit-transform: rotate(0deg) translateY(-50%);
      -ms-transform: rotate(0deg) translateY(-50%);
        transform: rotate(0deg) translateY(-50%);
      -webkit-transition: -webkit-transform 200ms ease-in-out;
      transition: -webkit-transform 200ms ease-in-out;
      -o-transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
			background-position: -316px -316px;
            height: 20px;
            width: 20px;
		}
        &:hover {
            opacity: 0.6;
            cursor: pointer;
        }
    }
    &__body {
        p:last-child {
            margin-bottom: 0;
        }
    }
}

@mixin notification-variant($color, $bg: transparent) {
    border-bottom: 3px solid darken($color, 10%);
    [class$="close"] {
        i {
            color: neutral-color('white');
        }
    }
    hr {
        opacity: 0.4;
        background-color: darken($color, 10%);
    }
    a {
        color: lighten($color, 20%);
        text-decoration: underline;
        &:hover {
            color: lighten($color, 10%);
            text-decoration: underline;
        }
    }
}

.ter-notification {
    @include notification-base();
}

@each $color, $value in $emotive-colors {
  .ter-notification--#{$color} {
    @include notification-variant($value);
  }
}
