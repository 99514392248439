@import "../global-styles/terra.scss";

.ter-headshot-dumpling {
  width: 256px;
  margin: 40px 16px;
  text-align: center;

  &__blue-filter {
    height: 150px;
    width: 150px;
    background: rgba(0, 95, 158, 0.4);
    border-radius: 50%;
    margin: auto;
  }

  &__image {
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: auto;
    mix-blend-mode: multiply;

    &--grayscale {
      filter: grayscale(100%);
    }

    &--blue {
      filter: grayscale(100%) brightness(130%);
    }
  }

  &__header {
    padding-top: spacing("xs");
    @include opensans-very-bold;
    font-size: font-size("base");
    margin: 0;
  }

  &__sub-header {
    @include opensans-medium;
    font-size: font-size("micro");
    padding-top: spacing("micro");
    margin: 0;
  }

  &__text {
    font-size: font-size("sm");
    @include opensans-light;
    padding-top: spacing("micro");
    margin: 0;
  }
}
