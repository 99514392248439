@import "../global-styles/terra.scss";

.ter-article-card {
  padding: 0;
  > a {
    text-decoration: none;
    display: block;
    transition: all 200ms ease-in-out;
    &:hover {
      background: color("white");
      box-shadow: box-shadow(4);
    }
    &:hover,
    &:active {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__link-wrap {
    @include vendor-prefix-display-flex;
    color: color("ui-1");
    text-decoration: none;
  }

  &__image {
    width: 162px;
    margin-right: spacing("md");
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  &__content {
    flex: 1;
    @include vendor-prefix-display-flex;
    @include vendor-prefix-flex-direction-column;
    @include vendor-prefix-justify-content-center;
  }

  &__category {
    font-size: font-size("nano");
    color: type-color("normal");
    margin-bottom: spacing("xxs");
  }

  &__header {
    font-size: font-size("md");
    margin-right: spacing("mini");
    color: type-color("normal");
    text-decoration: none;
  }
}
