@import "../../global-styles/terra.scss";

.ter-feat-six {
  margin: 0 auto;
  padding: spacing("super") spacing("lg");
  display: grid;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  &__header {
    font-size: font-size("lg");
    text-align: center;
  }

  &__sub-header {
    text-align: center;
  }

  .ter-cta-section {
    @media screen and (min-width: $breakpoint-xs) {
      margin: auto;
      flex-direction: row;
      justify-content: space-around;
    }

    .ter-button {
      margin: spacing("sm") 0 0;
      @include vendor-prefix-display-flex;
      justify-content: center;

      @media screen and (min-width: $breakpoint-xs) {
        text-align: center;
        padding-left: spacing("lg");
        padding-right: spacing("lg");
        margin-left: spacing("sm");
        margin-right: spacing("sm");
      }
    }
  }

  &--brand-regular {
    @include light-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
  }

  &--brand-dark {
    @include dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
  }

  &--brand-very-dark {
    @include very-dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
  }

  &--light {
    @include light-theme;
  }

  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
  }

  &--theme-2 {
    @include theme-2;
  }

  &--theme-3 {
    @include theme-3;
  }

  &--theme-4 {
    @include theme-4;

    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
  }

  &--theme-5 {
    @include theme-5;

    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
  }

  &__background-image {
    position: absolute;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}
