@import "../../global-styles/terra.scss";

.ter-mobile-menu {
  border-top: solid 1px $mep-gray-3;

  &__name {
    font-size: font-size("micro");
    margin: 0;
    height: 48px;
    line-height: 48px;
    background: $mep-gray-1;
    color: $mep-gray-3;
    padding: 0 16px;
    @include opensans-medium();
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  &__caret {
    width: 10px;
    position: absolute;
    top: 18px;
    right: 16px;
    transition: 0.2s;

    &--up {
      transform: rotate(180deg);
    }
  }

  &__link-container {
    background: $mep-gray-2;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: auto;
    max-height: 0;
    transition: 0.5s;

    &--is-open {
      max-height: 1000px;
    }
  }

  &__link {
    padding: 0;
    display: block;
    a {
      padding: 0 16px 0 36px;

      height: 48px;
      line-height: 48px;
      color: $mep-gray-3;
      font-size: font-size("micro");
      text-decoration: none;

      &:visited {
        color: color("gray-7");
      }
    }
  }
}
