@import "../../global-styles/terra.scss";

.ter-feat-five {
  padding-top: spacing("md");
  margin: auto;
  position: relative;

  &__header {
    font-size: font-size("xl");
    @include opensans-very-bold();
  }

  &__sub-header {
    @include opensans-light();
    color: color("gray-7");
  }

  &__sub-header,
  &__header {
    text-align: center;
  }

  &__dumpling-container {
    @include grid-container;
    margin-top: spacing("md");
  }

  &__dumpling-wrapper--b {
    @include grid-column(3);

    @media (max-width: 900px) {
      @include grid-column(6);
    }

    @media (max-width: 600px) {
      @include grid-column(12);
    }
  }

  &__dumpling-wrapper--a {
    @include grid-column(4);

    @media (max-width: 900px) {
      @include grid-column(12);
    }

    @media (max-width: 600px) {
      @include grid-column(12);
    }
  }

  &--brand-regular {
    @include light-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    a {
      @include brand-theme-link-text;
    }

    .ter-dumpling__icon-wrapper {
      svg {
        @include brand-theme-fill;
      }
    }
  }
  &--brand-dark {
    @include dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    a {
      @include brand-theme-link-text;
    }

    .ter-dumpling__icon-wrapper {
      svg {
        @include brand-theme-fill;
      }
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    a {
      @include brand-theme-link-text;
    }

    .ter-dumpling__icon-wrapper {
      svg {
        @include brand-theme-fill;
      }
    }
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
  }

  &--theme-2 {
    @include theme-2;
  }

  &--theme-3 {
    @include theme-3;
  }

  &--theme-4 {
    background: color("brand-2");

    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    a {
      @include brand-theme-link-text;
    }

    .ter-dumpling__icon-wrapper {
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &--theme-5 {
    background: color("brand-1");

    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    a {
      @include brand-theme-link-text;
    }

    .ter-dumpling__icon-wrapper {
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &__background-image {
    position: absolute;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}

@media (max-width: 1200px) {
  .ter-feat-five {
    &__header {
      font-size: font-size("lg");
    }

    .ter-icon {
      // transform: scale(0.66666);

      // LEFT OUT PER REC FROM JORDAN
    }
  }
}

@media (max-width: 600px) {
  .ter-feat-five {
    &__dumpling-container {
      justify-content: space-around;
    }
  }
}
