@import "../../global-styles/terra.scss";

.ter-feat-one {
  padding: spacing("lg") spacing("md");
  margin: auto;
  position: relative;
  color: $body-font-color;

  @media (min-width: $breakpoint-xs) {
    // max-width: 700px;
    // Commented out so that themes work correctly - were leaving the margins white
    // margin: auto;
    padding-top: spacing("mega");
  }

  @media (min-width: $breakpoint-sm) {
    @include grid-container;
  }

  &__background-image {
    position: absolute;
  }



  &--a {
    @include vendor-prefix-display-flex;
    flex-direction: column-reverse;

    @media (min-width: $breakpoint-sm) {
      @include vendor-prefix-display-flex;
      flex-direction: column-reverse;
    }

    @media (min-width: $breakpoint-md) {
      margin-bottom: 0;
      @include grid-container;
    }
  }

  &--b,
  &--c {
    @media (min-width: $breakpoint-sm) {
      padding: spacing("mega") spacing("md");
      align-items: center;
    }
  }

  &__image-wrapper {
    margin-bottom: spacing("md");

    @media (min-width: $breakpoint-sm) {
      @include grid-column(6);
    }
  &--right {
    grid-row: 1 / span 1;
    grid-column: 7 / span 6;
  }

    &--a {
      margin-top: spacing("lg");
      margin-bottom: 0;

      @media (min-width: $breakpoint-md) {
        @include grid-column(6);
        align-self: self-end;
        margin-top: 0;
      }
    }

    &--b,
    &--c {
      @media (min-width: $breakpoint-sm) {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    width: 100%;
  }

  &__content-container {
    @media (min-width: $breakpoint-sm) {
      @include grid-column(6);
    }

  &--right {
    margin-top: spacing("lg");
    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
  }

    &--a {
      @media (min-width: $breakpoint-md) {
        @include grid-column(6);
        padding-left: 16px;
      }
    }
  }

  &__header {
    font-size: font-size("lg");
    color: $body-font-color;
  }

  &__sub-header {
    font-size: font-size("base");
    @include opensans-light();
    color: $body-font-color;
  }

  &__text,
  &__text-holder p {
    margin-top: spacing("md");
    margin-bottom: 0;
  }

  .ter-button {
    display: block;
    margin-top: spacing("md");

    &__feat-one-cta {
      margin-right: 36px;
    }

    @media (min-width: $breakpoint-xs) {
      display: inline-block;
      padding-right: spacing("lg");
      padding-left: spacing("lg");
      // max-width: 200px;
    }

    @media (min-width: $breakpoint-md) {
      margin-top: spacing("md");
    }
  }

  &--brand-regular {
    @include light-brand-theme;
    h3,
    h4 {
      @include brand-theme-text;
    }
  }
  &--brand-dark {
    @include dark-brand-theme;
    h3,
    h4 {
      @include brand-theme-text;
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-theme;
    h3,
    h4 {
      @include brand-theme-text;
    }
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-2 {
    @include theme-2;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-3 {
    @include theme-3;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-4 {
    @include theme-4;

    h3,
    h4 {
      color: color("gray-1");
    }
  }

  &--theme-5 {
    @include theme-5;

    h3,
    h4 {
      color: color("gray-1");
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;

    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}
