@import "../../global-styles/terra.scss";

.ter-filter-hero {
  padding: 0 calc((100vw - 1200px) / 2) 48px;

  &__header-section {
    max-width: 500px;
    padding: 100px 0 100px 100px;
  }

  &__header {
    font-size: font-size("xl");
    margin: 0;
    @include opensans-semibold();
  }

  &__text,
  &__text-holder p {
    font-size: font-size("base");
    padding-top: 12px;
    margin: 0;
  }

  &__dropdown-container {
    margin-left: 100px;
    @include vendor-prefix-display-flex;
    flex-wrap: wrap;
  }

  &__checkbox-container {
    @include vendor-prefix-display-flex;
  }

  &__dropdown-label {
    margin-bottom: 0;
    @include opensans-medium();
    font-size: font-size("sm");
  }

  .ter-dropdown {
    width: 256px;
    margin-right: 20px;

    &__options-list {
      top: 50px;
    }
  }

  .ter-checkbox {
    margin: 0 20px;
    // transform: translateY(20px);
    align-self: flex-end;

    &__label {
      font-size: font-size("micro");
    }
  }

  &--brand-regular {
    @include light-brand-bg;

    h1,
    h2,
    h3,
    h4,
    p,
    .ter-checkbox__label {
      @include brand-theme-text;
    }

    .ter-checkbox__label:before {
      border: 1px solid $brand-theme-text-color;
    }

    .ter-checkbox__label:after {
      background: $brand-theme-text-color;
    }
  }

  &--brand-dark {
    @include dark-brand-bg;

    h1,
    h2,
    h3,
    h4,
    p,
    .ter-checkbox__label {
      @include brand-theme-text;
    }

    .ter-checkbox__label:before {
      border: 1px solid $brand-theme-text-color;
    }

    .ter-checkbox__label:after {
      background: $brand-theme-text-color;
    }
  }

  &--brand-very-dark {
    @include very-dark-brand-bg;

    h1,
    h2,
    h3,
    h4,
    p,
    .ter-checkbox__label {
      @include brand-theme-text;
    }
    .ter-checkbox__label:before {
      border: 1px solid $brand-theme-text-color;
    }

    .ter-checkbox__label:after {
      background: $brand-theme-text-color;
    }
  }

  &--light {
    @include light-theme;
  }

  &--dark {
    @include dark-theme;
  }
}
