@import "../global-styles/terra.scss";

.ter-filter-and-sort {
  background: gray("gray-2");
  padding: spacing("sm") 0;
  @include vendor-prefix-display-flex;
  @include vendor-prefix-justify-content-space-between;

  @media (max-width: 830px) {
    display: block;
    padding: spacing("sm") spacing("sm");
  }

  &__filter-container {
    margin-left: spacing("md");
    @media (max-width: 830px) {
      width: 100%;
      display: block;
      margin: 0;
    }
  }

  &__filter-wrapper {
    @include vendor-prefix-display-flex;
    @media (max-width: 830px) {
      display: block;
    }
  }

  &__filter-title,
  &__sort-title {
    font-size: font-size("micro");
    @include opensans-medium;
    margin-bottom: spacing("mini");
  }

  &__sort-container {
    min-width: 200px;
    margin-right: spacing("md");
    @media (max-width: 830px) {
      width: 100%;
      margin: 0;
    }
  }

  .ter-filter {
    margin-right: spacing("md");

    @media (max-width: 830px) {
      width: 100%;
      margin: 0 0 spacing("sm");
    }

    &__option-container {
      @media (max-width: 830px) {
        width: 100%;
      }
    }
  }
}
