@import "../global-styles/terra.scss";

.ter-accordion {
  margin-bottom: spacing("sm");
  border-bottom: $accordion-border-width solid $accordion-border-color;
  width: 100%;
  &__fold {
    font-size: $accordion-font-size;
    margin-bottom: spacing("sm");
    border-color: $accordion-border-color;
    border-width: $accordion-border-width;
    border-style: solid;
    border-left-width: 0px;
    border-right-width: 0px;
    -webkit-transition: background 225ms ease-in-out;
    -o-transition: background 225ms ease-in-out;
    transition: background 225ms ease-in-out;
    margin-bottom: 0;
    border-bottom: 0px;
    &:first-child {
      border-bottom: 0px;
    }
    &:last-child {
      border-bottom: 0px;
    }
  }
  &__trigger {
    font-size: $accordion-font-size;
    padding: spacing("xs") spacing("lg") spacing("xs") spacing("sm");
    position: relative;
    display: block;
    text-decoration: none;
    @include opensans-medium;
    width: 100%;
    text-align: left;
    line-height: line-height("loose");
    &-caret {
      position: absolute;
      color: $accordion-arrow-color;
      top: 50%;
      right: spacing("sm");
      -webkit-transform: rotate(0deg) translateY(-50%);
      -ms-transform: rotate(0deg) translateY(-50%);
      transform: rotate(0deg) translateY(-50%);
      -webkit-transition: -webkit-transform 200ms ease-in-out;
      transition: -webkit-transform 200ms ease-in-out;
      -o-transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out,
        -webkit-transform 200ms ease-in-out;
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  &__content {
    font-size: $accordion-font-size;
    padding: 0 spacing("sm");
    overflow-y: hidden;
    height: auto;
    max-height: 0;
    transition: 0.3s;
    p {
      font-size: $accordion-font-size;
    }
  }
  &__fold {
    &.is-expanded {
      .ter-accordion__content {
        max-height: 400px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .ter-accordion__trigger {
        text-decoration: none;
        &-caret {
          -webkit-transform: rotate(180deg) translateY(50%);
          -ms-transform: rotate(180deg) translateY(50%);
          transform: rotate(180deg) translateY(50%);
        }
      }
    }
  }
}
