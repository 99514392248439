@import "../global-styles/terra.scss";
@import "../Button/Button.scss";
.ter-tabs {
  @include for-size(tablet-landscape-down) {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    @include vendor-prefix-display-flex;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: $nav-tabs-border-size solid $nav-tabs-border-color;
    @media screen and (max-width: 900px) {
      display: block;
      border-bottom: none;
    }
    > li {
      padding: 0;
      margin-right: 0;
    }
  }
  &__list-item {
    padding: 0;
    margin: 0 0 -1px 0;
    button {
      @include button-base();

      padding: 0 48px;
      color: $nav-tabs-link-color;
      display: block;
      background-color: $nav-tabs-link-bg;
      font-weight: $nav-tabs-font-weight;
      border-left: $nav-tabs-border-size solid
        transparentize($nav-tabs-border-color, 0.75);
      border-top: $nav-tabs-border-size solid
        transparentize($nav-tabs-border-color, 0.75);
      border-right: 0px;
      border-bottom: $nav-tabs-border-size solid $nav-tabs-link-bg;
      &:hover {
        cursor: pointer;
        color: $nav-tabs-link-hover;
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        border-right: none;
        border-left: none;
      }
    }
    &:last-child {
      button {
        border-right: $nav-tabs-border-size solid
          transparentize($nav-tabs-border-color, 0.75);

        @media screen and (max-width: 900px) {
          border-right: none;
          border-left: none;
        }
      }
    }
    &.is-selected {
      button {
        border-left: $nav-tabs-border-size solid $nav-tabs-border-color;
        border-top: $nav-tabs-border-size solid $nav-tabs-border-color;
        border-right: $nav-tabs-border-size solid $nav-tabs-border-color;
        border-bottom: 0px;
        color: $nav-tabs-link-hover;
        background-color: $nav-tabs-active-link-bg;

        @media screen and (max-width: 900px) {
          border-right: none;
          border-left: none;
          border-bottom: $nav-tabs-border-size solid $nav-tabs-border-color;
          background-color: $nav-tabs-active-link-bg-mobile;
        }
      }
    }
    &.is-selected + li {
      > button {
        border-left: 0px;
      }
    }
  }
  &--full-width {
    .ter-tabs {
      &__list {
        @include vendor-prefix-justify-content-space-between;
      }
      &__list-item {
        @include vendor-prefix-flex-grow-1;
        button {
          width: 100%;
        }
      }
    }
  }

  &__content {
    padding-top: spacing("sm");
    padding-bottom: spacing("sm");
  }

  &--theme-1 {
    .ter-tabs {
      &__list {
        border-bottom: $nav-tabs-border-size solid
          $nav-tabs-border-color-theme-1;
      }

      &__list-item {
        button {
          color: $nav-tabs-link-color-theme-1;
          border-left: $nav-tabs-border-size solid
            $nav-tabs-border-color-theme-1;
          border-top: $nav-tabs-border-size solid $nav-tabs-border-color-theme-1;

          border-right: 0px;

          @media screen and (max-width: 900px) {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-1;
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-1;
          }

          border-bottom: $nav-tabs-border-size solid $nav-tabs-link-bg;
          &:hover {
            color: $nav-tabs-link-hover-theme-1;
          }
        }

        &.is-selected {
          button {
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-1;
            border-top: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-1;
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-1;
            color: $nav-tabs-text-color-theme-1;
            background-color: $nav-tabs-active-link-bg-theme-1;

            @media screen and (max-width: 900px) {
              border-bottom: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-1;
            }
          }
        }
        &.is-selected + li {
          > button {
            border-left: 0px;
            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-1;
            }
          }
        }

        &:last-child {
          button {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-1;

            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-1;
            }
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-1;
      }
    }
  }

  &--theme-2 {
    .ter-tabs {
      &__list {
        border-bottom: $nav-tabs-border-size solid
          $nav-tabs-border-color-theme-2;
      }

      &__list-item {
        button {
          color: $nav-tabs-link-color-theme-2;
          border-left: $nav-tabs-border-size solid
            $nav-tabs-border-color-theme-2;
          border-top: $nav-tabs-border-size solid $nav-tabs-border-color-theme-2;

          border-right: 0px;

          @media screen and (max-width: 900px) {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-2;
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-2;
          }

          border-bottom: $nav-tabs-border-size solid $nav-tabs-link-bg;
          &:hover {
            color: $nav-tabs-link-hover-theme-2;
          }
        }

        &.is-selected {
          button {
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-2;
            border-top: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-2;
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-2;
            color: $nav-tabs-text-color-theme-2;
            background-color: $nav-tabs-active-link-bg-theme-2;

            @media screen and (max-width: 900px) {
              border-bottom: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-2;
            }
          }
        }
        &.is-selected + li {
          > button {
            border-left: 0px;
            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-2;
            }
          }
        }

        &:last-child {
          button {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-2;

            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-2;
            }
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-2;
      }
    }
  }

  &--theme-3 {
    .ter-tabs {
      &__list {
        border-bottom: $nav-tabs-border-size solid
          $nav-tabs-border-color-theme-3;
      }

      &__list-item {
        button {
          color: $nav-tabs-link-color-theme-3;
          border-left: $nav-tabs-border-size solid
            $nav-tabs-border-color-theme-3;
          border-top: $nav-tabs-border-size solid $nav-tabs-border-color-theme-3;

          border-right: 0px;

          @media screen and (max-width: 900px) {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-3;
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-3;
          }

          border-bottom: $nav-tabs-border-size solid $nav-tabs-link-bg;
          &:hover {
            color: $nav-tabs-link-hover-theme-3;
          }
        }

        &.is-selected {
          button {
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-3;
            border-top: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-3;
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-3;
            color: $nav-tabs-text-color-theme-3;
            background-color: $nav-tabs-active-link-bg-theme-3;

            @media screen and (max-width: 900px) {
              border-bottom: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-3;
            }
          }
        }
        &.is-selected + li {
          > button {
            border-left: 0px;
            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-3;
            }
          }
        }

        &:last-child {
          button {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-3;

            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-3;
            }
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-3;
      }
    }
  }

  &--theme-4 {
    .ter-tabs {
      &__list {
        border-bottom: $nav-tabs-border-size solid
          $nav-tabs-border-color-theme-4;
      }

      &__list-item {
        button {
          color: $nav-tabs-link-color-theme-4;
          border-left: $nav-tabs-border-size solid
            $nav-tabs-border-color-theme-4;
          border-top: $nav-tabs-border-size solid $nav-tabs-border-color-theme-4;

          border-right: 0px;

          @media screen and (max-width: 900px) {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-4;
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-4;
          }

          border-bottom: $nav-tabs-border-size solid $nav-tabs-link-bg;
          &:hover {
            color: $nav-tabs-link-hover-theme-4;
          }
        }

        &.is-selected {
          button {
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-4;
            border-top: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-4;
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-4;
            color: $nav-tabs-text-color-selected-theme-4;
            background-color: $nav-tabs-active-link-bg-theme-4;

            @media screen and (max-width: 900px) {
              border-bottom: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-4;
            }
          }
        }
        &.is-selected + li {
          > button {
            border-left: 0px;
            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-4;
            }
          }
        }

        &:last-child {
          button {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-4;

            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-4;
            }
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-4;
      }
    }
  }

  &--theme-5 {
    .ter-tabs {
      &__list {
        border-bottom: $nav-tabs-border-size solid
          $nav-tabs-border-color-theme-5;
      }

      &__list-item {
        button {
          color: $nav-tabs-link-color-theme-5;
          border-left: $nav-tabs-border-size solid
            $nav-tabs-border-color-theme-5;
          border-top: $nav-tabs-border-size solid $nav-tabs-border-color-theme-5;

          border-right: 0px;

          @media screen and (max-width: 900px) {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-5;
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-5;
          }

          border-bottom: $nav-tabs-border-size solid $nav-tabs-link-bg;
          &:hover {
            color: $nav-tabs-link-hover-theme-5;
          }
        }

        &.is-selected {
          button {
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-5;
            border-top: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-5;
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-5;
            color: $nav-tabs-text-color-selected-theme-5;
            background-color: $nav-tabs-active-link-bg-theme-5;

            @media screen and (max-width: 900px) {
              border-bottom: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-5;
            }
          }
        }
        &.is-selected + li {
          > button {
            border-left: 0px;
            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-5;
            }
          }
        }

        &:last-child {
          button {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-5;

            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-5;
            }
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-5;
      }
    }
  }

  &--brand-dark {
    .ter-tabs {
      &__list {
        border-bottom: $nav-tabs-border-size solid
          $nav-tabs-border-color-brand-dark;
      }

      &__list-item {
        button {
          color: $nav-tabs-link-color-brand-dark;
          border-left: $nav-tabs-border-size solid
            $nav-tabs-border-color-brand-dark;
          border-top: $nav-tabs-border-size solid
            $nav-tabs-border-color-brand-dark;

          border-right: 0px;
          border-bottom: $nav-tabs-border-size solid $nav-tabs-link-bg;
          &:hover {
            color: $nav-tabs-link-hover-brand-dark;
          }
        }

        &.is-selected {
          button {
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-brand-dark;
            border-top: $nav-tabs-border-size solid
              $nav-tabs-border-color-brand-dark;
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-brand-dark;
            color: $nav-tabs-text-color-brand-dark;
            background-color: $nav-tabs-active-link-bg-brand-dark;
          }
        }
        &.is-selected + li {
          > button {
            border-left: 0px;
          }
        }

        &:last-child {
          button {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-brand-dark;
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-brand-dark;
      }
    }
  }
}

.ter-tabs-panel {
  &--theme-1 {
    background: color("gray-1");
  }
  &--theme-2 {
    background: color("gray-2");
  }
  &--theme-3 {
    background: color("gray-3");
  }
  &--theme-4 {
    background: color("brand-2");
  }
  &--theme-5 {
    background: color("brand-1");
  }

  &__wrapper {
    max-width: 1200px;
    margin: auto;
  }
}
