@import "../global-styles/terra.scss";

select {
  @include input-base();
  border: 0;
  background: transparent;
  &::-ms-expand {
    display: none;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  -o-text-overflow: "";
     text-overflow: "";
  outline: 0;
}

.ter-select__wrapper {
  @include input-base();
  -webkit-appearance: none;
  padding: 0;
  position: relative;

  .ter-icon {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}

.ter-select-options {
  @include depth(1);
  border: $dropdown-border-size solid $dropdown-border-color;
  border-radius: $dropdown-border-radius;
  min-width: 12.5em;
  position: absolute;
  top: calc(100%);
  padding: spacing("xxs") 0;
  left: 0;
  float: left;
  text-align: left;
  list-style: none;
  -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
          transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
  background: $dropdown-bg;
  margin: 0;
  -webkit-transition: opacity 225ms, visibility 225ms, -webkit-transform 225ms;
  transition: opacity 225ms, visibility 225ms, -webkit-transform 225ms;
  -o-transition: transform 225ms, opacity 225ms, visibility 225ms;
  transition: transform 225ms, opacity 225ms, visibility 225ms;
  transition: transform 225ms, opacity 225ms, visibility 225ms, -webkit-transform 225ms;
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  &.is-open {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(1px);
        -ms-transform: translateY(1px);
            transform: translateY(1px);
  }
  &.drop-up {
    top: unset;
    bottom: calc(100%);
    -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
            transform: translateY(10px);
    &.is-open {
      -webkit-transform: translateY(1px);
          -ms-transform: translateY(1px);
              transform: translateY(1px);
    }
  }

  &__item {
    @include type-setting();
    font-size: $dropdown-item-font-size;
    color: $dropdown-link-color;
    padding: spacing("mini") spacing("xs");
    display: block;
    text-decoration: none;
    &:hover {
      color: darken($dropdown-link-color, 10%);
      background: $dropdown-link-hover-bg;
      text-decoration: none;
    }
  }
}