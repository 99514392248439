@import "../../global-styles/terra.scss";

.ter-mobile-nav {
  position: absolute;
  top: 36px;
  width: 0;
  background-color: $mep-gray-0;
  z-index: 100;
  white-space: nowrap;
  color: $body-font-color;

  transition: 0.3s;
  overflow: scroll;
  height: 100vh;

  display: none;

  @media screen and (max-width: 900px) {
    display: block;
  }

  &--is-open {
    width: 100vw;
  }

  &__menus-container {
    font-size: font-size("nano");

    li {
      text-decoration: none;
      list-style: none;
    }
  }

  &__link {
    text-decoration: none;
    p {
      border-top: solid 1px $mep-gray-3;
      font-size: font-size("micro");
      margin: 0;
      height: 48px;
      line-height: 48px;
      background: $mep-gray-1;
      color: $mep-gray-3;
      padding: 0 16px;
      @include opensans-medium();
    }
  }
}

.ter-top-mobile-nav {
  padding: 16px;

  &__location-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 14px;
  }

  &__location {
    font-size: font-size("nano");
    padding-right: 9px;
    transform: translateY(-1px);
    // text-transform: uppercase;
  }

  &__globe-icon {
    width: 14px;
  }

  &__search-wrapper {
    display: grid;
    grid-template-columns: 1fr 22px;
    margin-top: 28px;
  }

  &__search-field {
    border: solid 1px $mep-gray-3;
    height: 37px;

    &:focus {
      outline: none;
    }
  }

  &__search-submit {
    margin: 0;
    padding: 0;
  }

  &__search-icon {
    width: 14px;
    display: block;

    margin-left: 8px;
  }

  &__link {
    font-size: font-size("nano");
    margin-top: 18px;
    display: block;
    color: $mep-gray-3 !important;
  }
}
