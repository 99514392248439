@import "../../global-styles/terra.scss";

.ter-footer {
  border-top: 1px solid #363545;

  background: color("gray-2");

  &__top-section-wrapper,
  &__middle-section-wrapper {
    background: color("gray-2");
  }

  &__bottom-section-wrapper {
    background: color("gray-1");
  }

  &__logo-wrapper {
    max-width: 1200px;
    margin: auto;
  }

  &__logo {
    width: 120px;
    padding: 30px 0 0 30px;
  }

  &__content {
    max-width: 1200px;
    margin: auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 3fr 320px 2fr;
    grid-template-columns: 3fr 320px 2fr;
    padding: 30px 30px 0;

    @media screen and (max-width: 900px) {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 600px) {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
  }

  &__left-side {
    padding-right: 80px;
    font-size: font-size("micro");

    @media screen and (max-width: 900px) {
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      grid-row: 1/3;
      padding-right: 40px;
    }

    @media screen and (max-width: 600px) {
      -ms-grid-row: 1;
      grid-row: 1;
      padding-right: 0px;
    }
  }

  &__text {
    font-size: font-size("micro");
    margin-bottom: 9px;
    padding-right: 32px;
  }

  &__more-about-link {
    text-decoration: none;
    margin-top: 0;
  }

  &__more-about-link,
  &__secondary-link {
    @include opensans-medium();
    text-decoration: none;
  }

  &__secondary-link {
    padding-bottom: 62px;
    display: block;
  }

  &__secondary-link-label {
    font-size: font-size("micro");
    margin-bottom: 0;
    margin-top: 48px;
  }

  &__link-containers {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;

    @media screen and (max-width: 600px) {
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-column: 1 / span 1;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
  }

  &__link-container {
  }

  &__link {
    height: 16px;
    margin: 0 0 24px;
    padding: 0;
    display: block;
    width: 160px;
    font-size: font-size("sm");
    @include opensans-semibold();

    @media screen and (max-width: 900px) {
      display: block;
    }

    @media screen and (max-width: 600px) {
      text-align: start;
    }

    @media screen and (max-width: 400px) {
      width: 120px;
    }
  }

  &__social-links-container {
    padding: 0 32px;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-column: 3 / span 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    height: 86px;
    max-width: 340px;
    justify-self: end;

    @media screen and (max-width: 900px) {
      -ms-grid-column: 2;
      grid-column: 2;
      -ms-grid-column-align: center;
      justify-self: center;
    }

    @media screen and (max-width: 600px) {
      -ms-grid-column: 1;
      grid-column: 1;
      margin: 32px 0 0;
      height: auto;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      padding: 0;
      max-width: 250px;
      justify-self: flex-start;
    }
  }

  &__social-link {
    display: block;
  }

  &__social-link-icon {
    width: 28px;
    margin: 0 16px 24px;

    @media screen and (max-width: 600px) {
      margin: 0 32px 24px 0;
    }
  }

  &__legal-stuff {
    max-width: 1200px;
    margin: auto;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 32px;
    font-size: font-size("nano");
    background: color("gray-1");

    @media screen and (max-width: 500px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-top: 16px;
      padding-bottom: 8px;
      height: auto;
    }

    span {
      padding: 0 6px;

      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    &-link {
      font-size: font-size("nano");
      display: inline;
      text-decoration: none;

      @media screen and (max-width: 500px) {
        display: block;
        margin-right: 16px;

        margin-bottom: 16px;
      }
    }
    @media screen and (max-width: 600px) {
      margin: auto;
    }
  }
}
