@import "../../global-styles/terra.scss";

.ter-feat-ten {
  padding-top: 96px;

  @media screen and (max-width: 600px) {
    // padding-top: 0;
  }

  &__header,
  &__sub-header {
    margin: 0;
    text-align: center;
    color: #262533;

    font-size: 28px;
    font-weight: 800;
    line-height: 1.2;

    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
  }

  &__sub-header {
    padding-top: 16px;
    font-size: font-size("base");
    @include opensans-light();
    color: $body-font-color;
  }

  .ter-tabs__list {
    padding-top: 48px;
  }

  .ter-card,
  .ter-icon-list-item {
    p {
      dfwhite-space: normal;
    }
  }
  .ter-tabs__content {
    padding: 0;
  }

  .ter-feat-four-flex {
    padding-top: 48px;
    @media screen and (max-width: 600px) {
      padding-bottom: 48px;
    }
  }
}
