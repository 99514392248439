@import "../../global-styles/terra.scss";

.ter-hero-five {
  margin: auto;
  position: relative;

  &__optional-container {
    padding: 0 32px;
    max-width: 1200px;
    text-align: center;
    margin: auto;
    position: relative;
    top: 50px;

    @media (max-width: 900px) {
      top: 20px;
    }
  }

  &__optional-header {
    font-size: font-size("lg");
  }

  &__optional-subheader {
    @include opensans-light();
  }

  @media (min-width: $breakpoint-sm) {
    @include grid-container();
    align-items: center;
  }

  &__image-container {
    @media (min-width: $breakpoint-sm) {
      @include grid-column(7);
      padding: spacing("super") 0;
    }

    @media (min-width: $breakpoint-md) {
      @include grid-column(6);
    }
  }

  &__image {
    width: 100%;
  }

  &__content-container {
    padding: 32px 32px 72px;

    @media (min-width: $breakpoint-sm) {
      @include grid-column(5);
      margin: 0;
      padding: 0 0 0 16px;
    }

    @media (min-width: $breakpoint-md) {
      @include grid-column(6);
    }
  }

  &__header {
    font-size: font-size("lg");

    @media (min-width: $breakpoint-md) {
      font-size: font-size("xxl");
    }
  }

  &__sub-header {
    @include opensans-light();
    margin-top: spacing("md");

    @media (min-width: $breakpoint-md) {
      font-size: font-size("lg");
    }
  }

  &__text,
  &__text-holder p {
    margin-top: spacing("md");

    @media (min-width: $breakpoint-md) {
      font-size: font-size("md");
    }
  }

  .ter-cta-section {
    @media (min-width: $breakpoint-xs) {
    }
  }

  .ter-button {
    text-align: center;
    display: block;
  }

  .ter-button--secondary--1 {
    margin-top: spacing("xs");
  }

  .ter-cta-section__link {
    display: block;
    text-align: center;
  }

  &--brand-regular {
    @include light-brand-theme;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--brand-dark {
    @include dark-brand-theme;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-theme;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    @include theme-2;
  }
  &--theme-3 {
    @include theme-3;
  }
  &--theme-4 {
    @include theme-4;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--theme-5 {
    @include theme-5;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }

  &__wrapper {
    position: relative;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}
