@import "../global-styles/terra.scss";

.ter-link-tile {
  padding: spacing("md");
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;

  &:hover {
    background: color("white");
    -webkit-box-shadow: box-shadow(4);
      box-shadow: box-shadow(4);
    text-decoration: none;
  }

  &__link-wrapper {
    margin-bottom: spacing("xs");
  }

  &__header {
    font-size: font-size("md");
    margin-right: spacing("mini");
    color: color("ui-1");
  }

  .ter-icon {
    fill: color("ui-1");
  }
}
