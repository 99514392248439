@import "../global-styles/terra.scss";

.ter-search-select {
  display: block;
  position: relative;
  outline: none;
  &:hover {
    cursor: pointer;
  }
  &__selected {
    @include input-base();
    outline: none;
    position: relative;
    .is-open & {
      border-color: color("ui-1");
      background-color: color("white");
    }

    &--warning {
      border-color: color("danger");
    }
  }
  &__caret {
    position: absolute;
    right: spacing("xs");
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  &__options-list {
    border: $dropdown-border-size solid $dropdown-border-color;
    position: absolute;
    top: calc(100% + #{$dropdown-border-size});
    padding: 0;
    left: 0;
    list-style: none;
    background: $dropdown-bg;
    width: 100%;
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-height: 20em;
    overflow: scroll;
    visibility: hidden;
    .is-open & {
      visibility: visible;
    }
  }
  &__options-list-item {
    @include type-setting();
    font-size: $dropdown-item-font-size;
    color: $dropdown-link-color;
    padding: spacing("xxs") spacing("xs");
    display: block;
    text-decoration: none;
    &:hover {
      color: darken($dropdown-link-color, 10%);
      background: $dropdown-link-hover-bg;
      text-decoration: none;
    }
  }
  &__options-list-item--inactive {
    &:hover {
      cursor: default;
      background: transparent;
    }
  }
  &__search-input {
    @include input-base();
    background: white;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: color("gray-4");
    &:hover,
    &:focus {
      border-color: color("gray-4");
    }
  }
}