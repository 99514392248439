@import "../../global-styles/terra.scss";

.luna-marketo-form {
  color: $body-font-color;
  padding: 0 16px;
  &__dropdown-label {
    margin-bottom: 0;
  }

  .ter-dropdown {
    margin-bottom: 24px;
  }

  .ter-button {
    margin-top: 24px;
  }

  .ter-form-item__label,
  .ter-checkbox__label {
    color: $body-font-color;
  }

  .ter-checkbox {
    padding-bottom: 0;
    padding-top: 16px;

    &__label {
      padding-bottom: 0;
    }
  }

  .ter-form-item__label-required-tag {
    font-size: 12px;
    transform: translateY(-4px);
    display: inline-block;
    color: color("danger");
  }

  &__warning-text {
    color: color("danger");
    padding-left: 16px;
    font-size: 16px;
    transform: translateY(12px);
    display: inline-block;
    margin: 0;

    @media screen and (max-width: 700px) {
      transform: translateY(0px);
      padding: 16px 0 0;
      display: block;
    }
  }
  ode &__bottom-text {
    margin: 0;
    padding: 16px 0;
    font-size: 16px;

    @media screen and (max-width: 700px) {
      // padding: 0;
    }
  }
}
