@import "../../global-styles/terra.scss";

.custom-feat {
  padding: spacing("super") calc((100vw - 1200px) / 2);
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative
  
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}