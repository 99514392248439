@import "../global-styles/terra.scss";

.ter-input {
  @include input-base();
}

.ter-form-item {
  &__required-tag {
    font-size: 12px;
    -webkit-transform: translateY(-4px);
    	-ms-transform: translateY(-4px);
        transform: translateY(-4px);
    display: inline-block;
    color: color("danger");
  }
}
