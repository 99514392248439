@import "../../global-styles/terra.scss";

.ter-feat-three {
  padding: 96px spacing("md");
  margin: auto;
  position: relative;
  color: $body-font-color;

  @media screen and (max-width: 600px) {
    padding: 48px;
  }

  &__image-and-content-container {
    @media (min-width: 1000px) {
      @include grid-container;
      // align-items: center;
    }
  }

  &__image--desktop {
    display: none;

    @media (min-width: 1000px) {
      display: block;
    }
  }

  &__image--tablet {
    display: none;

    @media (min-width: $breakpoint-xs) {
      display: block;
    }

    @media (min-width: 1000px) {
      display: none;
    }
  }

  &__image--mobile {
    @media (min-width: $breakpoint-xs) {
      display: none;
    }
  }

  &__image-wrapper {
    margin-bottom: 0;

    @media (min-width: 1000px) {
      @include grid-column(6);
    }
  }

  &__content-container {
    @media (min-width: 1000px) {
      @include grid-column(6);
      padding-left: 16px;
    }
  }

  &__image {
    width: 100%;
  }

  &__header {
    font-size: font-size("lg");
    text-align: center;
    color: $body-font-color;
    @include opensans-very-bold();
  }

  .ter-icon-list-item {
    display: block;

    @media (min-width: $breakpoint-xs) {
      @include vendor-prefix-display-flex;
    }
  }

  &__text {
    text-align: center;
    padding-bottom: 32px;

    @media (min-width: 1000px) {
      margin-bottom: 0;
    }
  }

  .ter-icon-list-item {
    margin-top: spacing("lg");
    padding: 0;

    &__header {
      margin-top: spacing("mini");
      margin-bottom: 0;
      color: $body-font-color;
      font-size: font-size("md");
    }

    &__body,
    &__body-wrapper p {
      margin-top: spacing("xxs");
      color: $body-font-color;

      @media (min-width: 1000px) {
        margin-bottom: 0;
      }
    }

    .ter-icon {
      margin-top: spacing("mini");
    }
  }

  .ter-link-card {
    margin-top: spacing("md");
    color: $body-font-color;

    &__text {
      font-size: font-size("base");
    }

    &__header {
      font-size: font-size("md");
      color: $body-font-color;
    }

    &__link {
      font-size: font-size("base");
    }

    .ter-icon {
      transform: translateY(9px) scale(0.7);
    }
  }

  .ter-button {
    display: block;

    @media (min-width: $breakpoint-xs) {
      display: inline-block;
      padding-left: spacing("lg");
      padding-right: spacing("lg");
    }

    margin-top: spacing("lg");
  }

  &--brand-regular {
    @include light-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }

    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
  }
  &--brand-dark {
    @include dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
  }
  &--brand-very-dark {
    @include very-dark-brand-theme;
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
    h2,
    h3,
    h4,
    button {
      color: $body-font-color;
    }
  }

  &--theme-2 {
    @include theme-2;

    h2,
    h3,
    h4,
    button {
      color: $body-font-color;
    }
  }

  &--theme-3 {
    @include theme-3;

    h2,
    h3,
    h4,
    button {
      color: $body-font-color;
    }
  }

  &--theme-4 {
    @include theme-4;
    h2,
    h3,
    h4,
    p,
    button {
      color: color("gray-1");
    }

    .ter-icon-list-item {
      &__header,
      p {
        color: color("gray-1");
      }
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &--theme-5 {
    @include theme-5;

    h2,
    h3,
    h4,
    p,
    button {
      color: color("gray-1");
    }

    .ter-icon-list-item {
      &__header,
      p {
        color: color("gray-1");
      }
      svg {
        @include brand-theme-fill;
      }
    }
  }

  &__background-image {
    position: absolute;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}
