@import "../../global-styles/terra.scss";

.ter-feat9 {
  margin: auto;
  padding: spacing("none");
  max-width: 1400px;
  @include vendor-prefix-display-flex;
  justify-content: center;
  padding: 96px 36px;
  position: relative;

  &--case-study {
    display: block;
  }

  &__image {
    width: 300px;
    height: 300px;

    box-sizing: content-box;
    justify-self: end;
    display: block;
  }

  &__full-quote-wrapper {
    @include vendor-prefix-display-flex;
    margin-left: spacing("lg");
  }

  &__quote-icon {
    height: 22px;
    margin-top: spacing("md");
  }

  &__content {
    margin: spacing("md") spacing("xs");
  }

  &__quote-source {
    @include opensans-medium();
    margin-bottom: spacing("xs");
    margin-left: spacing("mini");
  }
  &__quote-source-header {
    color: color("gray-6");
    font-size: font-size("micro");
    text-transform: uppercase;
    margin-left: spacing("mini");
  }
  &__quote-source-title {
    font-size: 14px;
    font-weight: 300;
  }

  &--brand-regular {
    @include light-brand-theme;
  }
  &--brand-dark {
    @include dark-brand-theme;
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }
  &--brand-very-dark {
    @include very-dark-brand-theme;
  }
  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    @include theme-2;
  }
  &--theme-3 {
    @include theme-3;
  }
  &--theme-4 {
    @include theme-4;
  }
  &--theme-5 {
    @include theme-5;
  }

  &__background-image {
    position: absolute;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }
}

@media (max-width: 900px) {
  .ter-feat9 {
    margin: spacing("none") spacing("auto");
    padding: 96px 36px 36px;

    // padding: spacing("none");
    // max-width: 400px;
    display: block;
    grid-template-columns: 1fr;

    &__image {
      // margin: spacing('auto');
      width: 300px;
      height: 300px;
      margin: auto;
    }

    &__full-quote-wrapper {
      max-width: 400px;
      margin: spacing("auto");
    }
  }
}

@media (max-width: 600px) {
  .ter-feat9 {
    &__full-quote-wrapper {
      max-width: 300px;
    }

    &__image {
      margin: spacing("auto");
      width: 200px;
      height: 200px;
    }

    .ter-button {
      width: 100%;
      text-align: center;
      margin: auto;
      display: block;
    }
  }
}
