@import "../../global-styles/terra.scss";

.luna-video-feat {
  text-align: center;
  font-family: $font-sans-serif;
  padding: 96px 0;
  margin: auto;
  position: relative;
  transition: 0.2s;

  .luna-video-feat__header,
  .luna-video-feat__sub-header,
  .luna-video-feat__text-content,
  .luna-video-feat__thumbnail,
  .luna-video-feat__play-btn {
    transition: 0.2s;
  }

  &__header {
    font-size: font-size("xl");
    font-weight: font-weight("extra-bold");
    line-height: 1.2;
    margin: 0 0 38px;
  }

  &__sub-header,
  &__sub-header-holder p {
    font-size: font-size("base");
    line-height: 1.6;
    margin: 0 0 30px;
  }

  &__text-content {
    padding: 0 32px;
    max-width: 752px;
    margin: auto;
  }

  &__thumbnail {
    max-width: 752px;
    display: block;
    margin: auto;
    // padding: 36px 36px
    transition: 0.2s;
    padding: 32px;

    @media screen and (max-width: 800px) {
      max-width: 100vw;
    }

    &--is-hovering {
      transform: scale(1.03);
      cursor: pointer;
    }

    &-container {
      position: relative;
    }
  }

  &__play-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &--is-hovering {
      transform: scale(1.03);
      cursor: pointer;
    }
  }

  &__video-wrapper {
    position: relative;

    &--default {
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
    }
    transition: 0.3s;
    transform: translateY(50px);
    z-index: 100;
    opacity: 0;

    &--is-deployed {
      transform: translateY(0);
      opacity: 1;
    }

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 90%;
      max-height: 90%;
      margin: auto;
    }
  }

  &__modal {
    position: fixed;
    -webkit-backface-visibility: hidden;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    animation: fade-in 0.5s linear;
    visibility: hidden;
    display: grid;
    align-items: center;

    &--is-deployed {
      opacity: 1;
      visibility: visible;
    }
  }

  &--brand-regular {
    @include light-brand-theme;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
  }
  &--brand-dark {
    @include dark-brand-theme;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
  }
  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }
  &--brand-very-dark {
    @include very-dark-brand-theme;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
  }

  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    @include theme-2;
  }
  &--theme-3 {
    @include theme-3;
  }
  &--theme-4 {
    @include theme-4;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
  }
  &--theme-5 {
    @include theme-5;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
  }

  &__background-image {
    position: absolute;
    opacity: 1;
    transition: opacity 0.2s;

    &--hidden {
      opacity: 0;
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;

    &--deployed {
      overflow-y: visible;
    }

    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }

    &--deployed {
      overflow: visible;
    }
  }

  &--deployed {
    // background: magenta !important;
    .luna-video-feat__header,
    .luna-video-feat__sub-header,
    .luna-video-feat__text-content,
    .luna-video-feat__thumbnail,
    .luna-video-feat__play-btn {
      opacity: 0 !important;
    }
  }
}
