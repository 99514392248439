@import "../global-styles/terra.scss";

.ter-breadcrumbs {
  &__list {
    @include border-radius(all, small);
    font-size: font-size("sm");
    padding: spacing("none");
    margin-top: 0;
    margin-bottom: spacing("xs");
    list-style: none;
  }
  &__list-item {
    display: inline-block;
    a {
    }
  }
  &__caret {
    margin-left: spacing("micro");
    margin-right: spacing("micro");
    fill: $breadcrumbs-caret-color;
  }
}
