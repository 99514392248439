@import "../global-styles/terra.scss";

.ter-event-card {
  &__header {
    @include opensans-very-bold;
  }
  &__date {
    display: block;
    font-size: font-size("micro");
    @include opensans-medium;
    margin-bottom: spacing("xs");
  }
  &__venue {
    display: block;
    @include opensans-light;
    margin-bottom: spacing("micro");
  }
  &__location {
    display: block;
    font-size: font-size("nano");
    @include opensans-medium;
    margin-bottom: spacing("md");
  }
  &__cta-section--2-col {
    display: block;
    margin-top: auto;
    @include for-size("tablet-portrait-up") {
      display: -ms-grid;
      display: grid;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -ms-grid-columns: 1fr spacing("xs") 1fr;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: spacing("xs");
    }
  }
  &__cta {
    margin-top: 0;
    display: block;
    width: 100%;
    @include vendor-prefix-flex-direction-column;
    &--two {
      margin-top: spacing("xs");
      @include for-size("tablet-portrait-up") {
        margin-top: 0;
      }
    }
  }
}
