@import "../../global-styles/terra.scss";

.ter-mobile-mega-menu {
  border-top: solid 1px $mep-gray-3;
  padding: 0;
  background: $mep-gray-1;

  &__name {
    font-size: font-size("micro");
    margin: 0;
    height: 48px;
    line-height: 48px;
    background: $mep-gray-1;
    color: $mep-gray-3;
    padding: 0 16px;
    @include opensans-medium();
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  &__caret {
    width: 10px;
    position: absolute;
    top: 18px;
    right: 16px;
    transition: 0.2s;

    &--up {
      transform: rotate(180deg);
    }
  }

  &__category-container {
    height: auto;
    transition: 0.5s;
    overflow: hidden;
    max-height: 0;

    &--is-open {
      max-height: 1000px;
    }
  }

  &__bottom-link {
    background: $mep-blue-1;
    font-size: 14px !important;
    color: gray("white") !important;
    margin: 8px auto;
    display: block;
    width: min-content;
    height: 32px;
    line-height: 32px;
    padding: 0 24px;
  }
}

.ter-mobile-mega-menu-category {
  border-top: solid 1px $mep-gray-1;

  &__name,
  &__single-link {
    font-size: font-size("micro");
    margin: 0;
    height: 48px;
    line-height: 48px;
    background: $mep-blue-2;
    color: gray("white");
    padding: 0 16px 0 36px;
    @include opensans-medium();
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &--single {
      font-size: font-size("micro");
      margin: 0;
      height: 48px;
      line-height: 48px;
      background: $mep-blue-2;
      color: gray("white");
      padding: 0 16px 0 36px;
      @include opensans-medium();
      position: relative;
    }
  }

  &__single-link {
    padding-left: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__caret {
    width: 10px;
    position: absolute;
    top: 18px;
    right: 16px;
    transition: 0.2s;

    &--up {
      transform: rotate(180deg);
    }
  }

  &__submenu {
    background: $mep-gray-2;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: auto;
    max-height: 0;
    transition: 0.5s;

    &--is-open {
      max-height: 1000px;
    }

    &-link {
      padding: 0;
      display: block;
      a {
        padding: 0 16px 0 52px;
        height: 48px;
        line-height: 48px;
        color: $mep-gray-3 !important;
        font-size: font-size("micro");
      }
    }
  }
}
